import { Card, CardBody, Text, Heading, Button, VisuallyHidden, Box } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import NextLink from 'next/link'
import { FunctionComponent } from 'react'

import Image from 'components/elements/Image'
import parseRelatedNames from 'components/modules/LandingPageCategory/utils/parseRelatedNames'
import { LandingPageCategoryData } from 'contentful/pages/dynamic/config/pageLandingPageCategory'
import formatDate from 'utils/formatDate'
import { getLocale } from 'utils/getLocale'
import hasProperty from 'utils/hasProperty'
import useResolveDynamicPage from 'utils/Providers/DynamicPage/hooks/useResolveDynamicPage'

type Featured = ArrayValues<Required<LandingPageCategoryData>['featured']['items'] | Contentful.IPageArticle[]>

interface FeaturedCardBaseProps extends CardProps {
  /**
   * Variant of the card.
   * @default 'multiple'
   */
  variant?: 'single' | 'multiple'
  /**
   * Should image and content be reversed?
   * @default false
   */
  isReversed?: boolean
  /**
   * Should image be loaded with priority?
   * @default false
   */
  priority?: boolean
}

type FeaturedCardProps = FeaturedCardBaseProps & { card: Featured }

/**
 * Used for E2E tests.
 */
const TAG = 'FeaturedCard'

const FeaturedCard: FunctionComponent<FeaturedCardProps> = ({
  bgColor = 'secondary.beige.100',
  variant = 'multiple',
  isReversed = false,
  priority = false,
  card,
}) => {
  const { t, lang } = useTranslation()
  const locale = getLocale(lang)

  const resolveLink = useResolveDynamicPage()

  const productsAndProcesses = parseRelatedNames(card)

  if (!card) {
    return null
  }

  return (
    <Card
      data-sys-id={card.sys.id}
      data-testid={TAG}
      variant="unstyled"
      direction={{ base: 'column', md: variant === 'multiple' ? 'column' : isReversed ? 'row-reverse' : 'row' }}
      bgColor={bgColor}
      maxW={{ base: '100%', md: variant === 'multiple' ? 'main-block' : 'desktop' }}
      borderRadius={0}
      w="100%"
    >
      {hasProperty(card, 'heroContent') && (
        <Box height={{ base: 200, md: variant === 'multiple' ? 322 : 510 }} w="100%">
          <Image
            data-contentful-field-id="image"
            data-contentful-entry-id={card.heroContent?.sys?.id}
            priority={priority}
            maxW="100%"
            src={card.heroContent?.image?.url || ''}
            alt={card.heroContent?.image?.description || ''}
            focalPoint={card.heroContent?.focalPoint}
            originalWidth={card.heroContent?.image?.width}
            originalHeight={card.heroContent?.image?.height}
            width={680}
            minH="full"
            height={{ base: 200, md: variant === 'multiple' ? 322 : 510 }}
            blurDataURL={card.heroContent?.image?.blurDataURL}
          />
        </Box>
      )}

      <CardBody
        p={{ base: 4, md: variant === 'multiple' ? 6 : 10 }}
        minW={{ base: '100%', md: variant === 'multiple' ? '100%' : '50%' }}
        minH={{ base: 0, md: variant === 'multiple' ? '322px' : '510px' }}
      >
        <Text mb={4} _empty={{ display: 'none' }}>
          {[hasProperty(card, 'date') && formatDate(card.date, locale), hasProperty(card, 'location') && card.location]
            .filter(Boolean)
            .join(', ')}
        </Text>
        {hasProperty(card, 'publishDate') && card.publishDate && (
          <Text mb={4} data-contentful-field-id="publishDate" data-contentful-entry-id={card.sys?.id}>
            {formatDate(card.publishDate, locale)}
          </Text>
        )}
        {hasProperty(card, 'contractor') && card.contractor && (
          <Text mb={4} data-contentful-field-id="contractor" data-contentful-entry-id={card.sys?.id}>
            {card.contractor}
          </Text>
        )}

        <Heading
          size="lg"
          mb={4}
          noOfLines={3}
          data-contentful-field-id="heading"
          data-contentful-entry-id={card.sys?.id}
        >
          {card.heading}
        </Heading>

        {hasProperty(card, 'preamble') && card.preamble && (
          <Text mb={6} noOfLines={6} data-contentful-field-id="preamble" data-contentful-entry-id={card.sys?.id}>
            {card.preamble}
          </Text>
        )}

        {productsAndProcesses && (
          <>
            <Heading size="sm">{t('pages.success-stories.processes-and-products-heading')}</Heading>
            <Text mb={6}>{productsAndProcesses}</Text>
          </>
        )}

        <Button as={NextLink} variant="secondary" href={resolveLink(card.sys.id)} bg="white">
          {hasProperty(card, 'heading') && card.heading && <VisuallyHidden>{`${card.heading};`}</VisuallyHidden>}
          {t('pages.success-stories.learn-more-button-label')}
        </Button>
      </CardBody>
    </Card>
  )
}

FeaturedCard.displayName = TAG

export default FeaturedCard
