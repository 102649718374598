import { Box, Button, Container, Flex, Heading } from '@chakra-ui/react'
import { NodeData } from '@contentful/rich-text-types'
import { default as NextLink } from 'next/link'
import { ComponentProps, FunctionComponent } from 'react'

import Image from 'components/elements/Image'
import getHref from 'components/elements/NavigationItem/utils/getHref'
import RichTextPageContent from 'components/elements/RichTextPageContent'
import useResolveDynamicPage from 'utils/Providers/DynamicPage/hooks/useResolveDynamicPage'

interface HeroImageProps extends CardProps, Omit<ComponentProps<typeof Container>, 'ref'> {
  /**
   * Will be displayed as a <h1 />.
   */
  title?: string | undefined
  titleId?: string
  description?: NodeData | undefined
  button?: Contentful.IComponentNavigationItem | undefined
  openButtonLinkInNewTab?: boolean
  entryId?: string
}

const TAG = 'HeroImage'

const HERO_HEIGHT = { base: 300, md: 400, lg: 505, xl: 600 }

const DEFAULT_PX = { base: 10, '2xl': 0 }

const HeroImage: FunctionComponent<HeroImageProps> = ({
  maxW = 'full',
  maxH = HERO_HEIGHT,
  title,
  description,
  button,
  openButtonLinkInNewTab,
  image,
  focalPoint,
  px = DEFAULT_PX,
  entryId,
  titleId,
  ...props
}) => {
  const resolveLink = useResolveDynamicPage('')
  const href = getHref({
    targetUrl: button?.targetUrl,
    targetReference: button?.targetReference,
    sysId: button?.sys?.id,
    resolveLink,
  })

  const isContentDisplayed = !!(title || description || button)
  const isImageDisplayed = image?.url
  const textColor = isImageDisplayed ? 'white' : 'black'
  const isBackgroundColorDisplayed = isImageDisplayed && title && !description && !button

  return (
    <Box data-testid={TAG} as="header" color="white" position="relative" display="grid" maxW={maxW}>
      {isImageDisplayed && (
        <Box w="full" gridArea="1 / -1" marginInline="auto" minH="100%" height={maxH}>
          <Image
            maxW="100%"
            src={image.url}
            alt=""
            role="presentation"
            priority={true}
            loading="eager"
            width={{
              // Shifted breakpoint values - @see breakpoints in theme.ts
              base: 480,
              sm: 720,
              md: 960,
              lg: 1200,
              xl: 1440,
              '2xl': 1920,
            }}
            height={maxH}
            minH="100%"
            minWidth="100%"
            // minWidth has higher priority than maxWidth,
            // but that will fix overflow when image couldn't be loaded
            maxWidth="0"
            userSelect="none"
            blurDataURL={image?.blurDataURL}
            focalPoint={focalPoint}
            originalWidth={image.width}
            originalHeight={image.height}
          />
        </Box>
      )}

      {isContentDisplayed && (
        <Container display="flex" alignItems="flex-end" maxW="full" gridArea="1 / -1" pt={10} pb={0} px={0} {...props}>
          <Flex
            w="full"
            px={px}
            pt={5}
            pb={isBackgroundColorDisplayed ? 5 : 10}
            // Override left padding to align heading with the page content if there is no image
            {...(!isImageDisplayed && px !== DEFAULT_PX && { pl: '0 !important' })}
            justifyContent="center"
            {...(isBackgroundColorDisplayed && { bg: 'rgba(0, 0, 0, 0.56)' })}
          >
            <Flex w="full" maxW="desktop-content">
              <Flex direction="column" w="full" maxW={{ base: 'full', lg: '70%' }}>
                {title && (
                  <Heading
                    as="h1"
                    variant="h1"
                    fontSize={{ base: 'xl', md: '2xl' }}
                    textTransform="uppercase"
                    wordBreak="break-word"
                    color={textColor}
                    data-contentful-field-id={titleId || 'title'}
                    data-contentful-entry-id={entryId}
                  >
                    {title}
                  </Heading>
                )}
                {description && (
                  <RichTextPageContent
                    my={6}
                    w="full"
                    maxW={{ base: 'full', lg: '50%' }}
                    color={textColor}
                    richTextBodyField={description}
                    data-contentful-field-id="description"
                    data-contentful-entry-id={entryId}
                  />
                )}
                {button?.title && href && (
                  <Button
                    data-contentful-field-id="button"
                    data-contentful-entry-id={entryId}
                    variant={isImageDisplayed ? 'outline' : 'primary'}
                    as={NextLink}
                    href={href}
                    w="min-content"
                    target={openButtonLinkInNewTab ? '_blank' : undefined}
                    rel="noopener noreferrer"
                  >
                    {button.title}
                  </Button>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Container>
      )}
    </Box>
  )
}

export default HeroImage
