import { sizes } from 'theme/foundations/sizes'

/**
 * The 'size' property is used to retrieve the value from the sizes array in 'XX px' format.
 * Fetched string is modified to contain digits only and converted to a number type.
 */
const getNumericSize = (size: ContainerSize) =>
  size ? parseInt((sizes[size]?.match(/\d+/)?.flat() || [])[0]) || undefined : undefined

export default getNumericSize
