
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { Container } from '@chakra-ui/react'

import BlockHeroBanner from 'components/elements/Blocks/BlockHeroBanner'
import BlocksManager from 'components/elements/Blocks/BlocksManager'
import getRevalidate from 'config/revalidate'
import { getHomePageData, HomePageData } from 'contentful/pages/home'
import withPageStaticProps from 'utils/next/withPageStaticProps'

interface StartPageProps extends HomePageData {}

const TAG = 'Home'

const StartPage: NextPageWithLayout<StartPageProps> = ({ heroBanner, pageBlocks }) => (
  <Container data-testid={TAG} maxW="full" p={0}>
    {heroBanner && <BlockHeroBanner {...heroBanner} />}
    <BlocksManager pageBlocks={pageBlocks?.items} />
  </Container>
)

export default StartPage

 const _getStaticProps = withPageStaticProps<StartPageProps>(async ({ locale, preview = false }) => {
  if (locale === 'default' || !locale) {
    return { notFound: true }
  }

  const pageContent = await getHomePageData({ lang: locale, preview })

  return {
    props: {
      layout: { props: { seo: pageContent?.seoMetaTags || null, preview } },
      ...pageContent,
    },
    revalidate: getRevalidate(),
  }
})


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index.page',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  