import { Container } from '@chakra-ui/react'
import { FunctionComponent } from 'react'

import HeroImage from 'components/elements/HeroImage'

interface BlockHeroBannerProps extends Contentful.IBlockHeroBanner {}

const TAG = 'BlockHeroBanner'

const BlockHeroBanner: FunctionComponent<BlockHeroBannerProps> = (pageBlock) => {
  if (!pageBlock) {
    return null
  }
  return (
    <Container data-testid={TAG} maxW="full" p={0}>
      <HeroImage
        entryId={pageBlock.sys.id}
        title={pageBlock.heading}
        image={pageBlock.heroContent?.image}
        focalPoint={pageBlock.heroContent?.focalPoint}
        maxH={{ base: 400, md: 500 }}
        description={pageBlock.description}
        button={pageBlock.button}
        openButtonLinkInNewTab={pageBlock.openButtonLinkInNewTab}
      />
    </Container>
  )
}
export default BlockHeroBanner
