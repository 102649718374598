const defaultOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}

const formatDate = (
  date: string | undefined,
  lang: string,
  options: Intl.DateTimeFormatOptions = defaultOptions,
): string => (date ? new Date(date).toLocaleDateString(lang, options) : '')

export default formatDate
