import hasProperty from 'utils/hasProperty'

interface Card {
  internalName: string | undefined
  heading: string | undefined
}

function mapNames(items: (Card | null)[] = []): string[] {
  return items
    .filter((item): item is Card => !!item)
    .map((_) => _.heading || _.internalName)
    .filter((name): name is string => !!name)
}

function getItems(card: unknown, name: string): Card[] {
  if (hasProperty(card, name)) {
    const values = card[name]
    if (hasProperty(values, 'items') && Array.isArray(values.items)) {
      return values.items
    }
  }

  return []
}

/**
 * Parse related processes and products from a card.
 */
const parseRelatedNames = (card: unknown): string | undefined => {
  if (!card) {
    return undefined
  }

  const relatedProcesses = mapNames(getItems(card, 'relatedProcesses'))
  const relatedProducts = mapNames(getItems(card, 'relatedProducts'))

  return [...relatedProcesses, ...relatedProducts].join(', ')
}

export default parseRelatedNames
