import { Card, CardBody, Text, Heading } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import NextLink from 'next/link'
import { FunctionComponent } from 'react'

import Image from 'components/elements/Image'
import parseRelatedNames from 'components/modules/LandingPageCategory/utils/parseRelatedNames'
import { LandingPageCategoryData } from 'contentful/pages/dynamic/config/pageLandingPageCategory'
import formatDate from 'utils/formatDate'
import { getLocale } from 'utils/getLocale'
import getNumericSize from 'utils/getNumericSize'
import hasProperty from 'utils/hasProperty'
import useResolveDynamicPage from 'utils/Providers/DynamicPage/hooks/useResolveDynamicPage'

type Page = ArrayValues<Required<LandingPageCategoryData>['pages']['items']> | Contentful.IPageCampaign

interface PageCardBaseProps extends CardProps {}

type PageCardProps = PageCardBaseProps & { card: Page }

/**
 * Used for E2E tests.
 */
const TAG = 'PageCard'

const styles = {
  link: {
    w: 'full',
    h: 'full',
    borderRadius: 0,
    _hover: { boxShadow: 'lg' },
    _focusVisible: { boxShadow: 'lg', outline: 'none' },
    transitionProperty: 'box-shadow',
    transitionDuration: '0.2s',
  },
  body: {
    p: 6,
    px: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
} as const

const PageCard: FunctionComponent<PageCardProps> = ({ maxW = 'side-block', bodyStyles, card }) => {
  const { lang } = useTranslation()
  const locale = getLocale(lang)
  const resolveLink = useResolveDynamicPage()
  const productsAndProcesses = parseRelatedNames(card)

  if (!card) {
    return null
  }

  const heroContent =
    (hasProperty(card, 'heroContent') && card.heroContent) ||
    (hasProperty(card, 'heroBanner') && hasProperty(card.heroBanner, 'heroContent') && card.heroBanner.heroContent) ||
    null
  const author = (hasProperty(card, 'author') && card.author) || null
  const date = (hasProperty(card, 'publishDate') && card.publishDate) || (hasProperty(card, 'date') && card.date)

  const imageWidth = getNumericSize(maxW) || 322
  const imageHeight = imageWidth / 1.67

  return (
    <Card
      data-testid={TAG}
      data-sys-id={card.sys.id}
      as={NextLink}
      href={resolveLink(card.sys.id)}
      variant="unstyled"
      aria-label={card.heading}
      {...styles.link}
      maxW={maxW}
    >
      {heroContent && (
        <Image
          data-contentful-field-id="image"
          data-contentful-entry-id={heroContent.sys?.id}
          src={heroContent.image?.url || ''}
          alt={heroContent.image?.title || ''}
          focalPoint={heroContent.focalPoint}
          originalWidth={heroContent.image?.width}
          originalHeight={heroContent.image?.height}
          maxW="100%"
          width={imageWidth}
          height={{ base: 322 / 1.67, md: imageHeight }}
          blurDataURL={heroContent.image?.blurDataURL}
        />
      )}
      <CardBody {...styles.body} {...bodyStyles}>
        <Heading
          as="h2"
          size="sm"
          noOfLines={3}
          data-contentful-field-id="heading"
          data-contentful-entry-id={card.sys?.id}
        >
          {card.heading}
        </Heading>
        {hasProperty(card, 'preamble') && card.preamble && (
          <Text noOfLines={4} data-contentful-field-id="preamble" data-contentful-entry-id={card.sys?.id}>
            {card.preamble}
          </Text>
        )}
        {productsAndProcesses && (
          <Text
            fontSize="sm"
            color="secondary.grey.900"
            noOfLines={3}
            mt="auto"
            data-contentful-field-id="relatedProcesses"
            data-contentful-entry-id={card.sys?.id}
          >
            {productsAndProcesses}
          </Text>
        )}
        {date && (
          <Text
            fontSize="sm"
            color="secondary.grey.900"
            mt="auto"
            data-contentful-field-id={hasProperty(card, 'publishDate') ? 'publishDate' : 'date'}
            data-contentful-entry-id={card.sys?.id}
          >
            {formatDate(date, locale)}
            <span data-contentful-field-id="author" data-contentful-entry-id={card.sys?.id}>
              {author?.fullName && <> | {author.fullName}</>}
            </span>
          </Text>
        )}
      </CardBody>
    </Card>
  )
}

PageCard.displayName = TAG

export default PageCard
